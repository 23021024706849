import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
  `}<p parentName="div">{`  I told all those in the crowd of 6,900 that God loves them and that they will never be satisfied without Him. We give the Lord all the glory for the hundreds who responded by making life-changing decisions for Jesus Christ. Each one of these new believers received a Bible and resources that will help them grow in their walk with Him. `}</p>{`
  `}<p parentName="div">{`  Fadi* was among those who walked forward when I gave the invitation. He had moved to Germany from Syria just seven years ago. `}</p>{`
  `}<div parentName="div" {...{
        "className": "image-float-left"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2023/11/Essen-counsel_231001_TP2_4748_INET.jpg",
          "alt": ""
        }}></img>{`
  `}</div>{`
  `}<p parentName="div">{`  The Holy Spirit worked in Fadi’s heart as he listened carefully to the Gospel. When he met with one of our prayer counselors, he said he had been going to church for some time—but he had never made a decision for the Lord. `}<strong parentName="p">{`“It [has] been a decision of many years,” he shared. We praise God for this new brother.`}</strong></p>{`
  `}<p parentName="div">{`  Maxceale,* a 29-year-old teacher from Düsseldorf, also responded to the Good News of Jesus Christ. She had been attending church for a couple of months but had not yet surrendered her life to the Lord. When a friend invited her to the Festival, she agreed to go—and when she heard the Gospel, she knew she had to act. “I knew there was something missing. Since third grade, I’ve been looking for something,” Maxceale said, adding that she had used worldly pleasures and pursuits to fill the empty feeling. “I really feel the freedom of giving it up.”`}</p>{`
  `}<p parentName="div">{`  Will you pray for Fadi, Maxceale, and each person who received Jesus Christ in repentance and faith? Ask God to strengthen them as they `}<em parentName="p">{`“fight the good fight of the faith. `}</em>{`[`}<em parentName="p">{`And`}</em>{`]`}<em parentName="p">{` take hold of the eternal life”`}</em>{` (1 Timothy 6:12, ESV). `}</p>{`
  `}<h4 parentName="div">{`Equipping Hispanic Believers`}</h4>{`
  `}<p parentName="div">{`  I thank God for the opportunity He has given me to take His Gospel to cities along our country’s southern border early next year. The needs—spiritual, physical, and emotional—run deep on both sides of the border. As we talk to church leaders in the region who are helping prepare for the God Loves You Frontera Tour, we hear about the same heart-wrenching difficulties over and over: poverty, joblessness, broken family relationships, addiction, and overall uncertainty. `}</p>{`
  `}<div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
  `}<img parentName="div" {...{
          "style": {
            "maxWidth": "20rem"
          },
          "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2023/11/Screen-Shot-2023-11-03-at-2.25.27-PM.png",
          "alt": ""
        }}></img>{`
  `}</div>{`
  `}<p parentName="div">{`  So many people are working hard now `}<em parentName="p">{`“equipping … the saints for the work of ministry”`}</em>{` (Ephesians 4:12, NKJV) ahead of the tour. `}</p>{`
  `}<p parentName="div">{`  `}<strong parentName="p">{`Dozens of churches in Texas, Arizona, and California are hosting the Christian Life and Witness Course, helping inspire and equip local believers to share their faith`}</strong>{`—through the tour event in their city and on their own.`}</p>{`
  `}<p parentName="div">{`  Last month, we also had the privilege of hosting four Evangelism Summits for Hispanic pastors and ministry leaders in McAllen and El Paso, Texas; Phoenix, Arizona; and San Diego, California. I’m grateful for the hundreds of believers who attended these events. During each summit, attendees participated in Biblical teaching on evangelism and discipleship, as well as Christ-centered fellowship and worship.`}</p>{`
  `}<img parentName="div" {...{
        "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2023/11/HIM_231003_LR1_9826-Enhanced-NR_INET.jpg",
        "alt": ""
      }}></img>{`
  `}<figcaption parentName="div">{`HISPANIC LEADERS AND MINISTERS heard inspiring, Biblical messages on effective evangelism and discipleship.`}</figcaption>{`
  `}<p parentName="div">{`  Many of the sessions centered on faithfully preaching `}<em parentName="p">{`“the whole counsel of God”`}</em>{` (Acts 20:27, ESV). “Christ did not call us to comfort,” said Eli Bonilla Jr., one of the summit speakers. `}<strong parentName="p">{`“He called us to carry a cross because we know our hope is secured in eternity.”`}</strong></p>{`
  `}<p parentName="div">{`  His message resonated strongly with many of the attendees, including Jonathan Guerrero, a ministry leader from Mexico. “The word preached here awakens you to the great need out there to evangelize and disciple believers by preaching the truth,” he shared. “We can no longer preach a watered-down Gospel or a prosperity Gospel. The only thing that transforms lives is the truth.”`}</p>{`
  `}<p parentName="div">{`  Will you pray for these leaders and pastors? Many of them are already helping mobilize other believers in their communities for the God Loves You Frontera Tour. `}<strong parentName="p">{`Ask God to strengthen them as they boldly share His truth`}</strong>{` and equip others to do the same.`}</p>{`
  `}<h4 parentName="div">{`Noi Festival in Rome`}</h4>{`
  `}<p parentName="div">{`  As 2023 winds down, we are finishing the year strong with a full schedule of evangelistic outreaches. My son `}<strong parentName="p">{`Will shared the Gospel with hundreds in Canada a few weeks ago`}</strong>{`, and as I write, I’m on my way to Rome, where I’ll preach during the two-day Noi Festival. I’ve been looking forward to returning to Italy since I preached to thousands in Milan last year, and I’m thankful for the opportunity to once again proclaim Jesus Christ, `}<em parentName="p">{`“in whom we have redemption, the forgiveness of sins”`}</em>{` (Colossians 1:14, ESV).`}</p>{`
  `}<img parentName="div" {...{
        "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2023/11/WG-Canada_crowd_RN1_0598_INET.jpg",
        "alt": ""
      }}></img>{`
  `}<figcaption parentName="div">{`HUNDREDS HEARD THE GOSPEL during Will’s recent Thompson-Okanagan Look Up Tour in British Columbia, Canada.`}</figcaption>{`
  `}<p parentName="div">{`  `}<strong parentName="p">{`Will you pray for each person who hears the Gospel in Rome?`}</strong>{` Ask God to work mightily through the Festival and draw people from all walks of life to Himself. Please also pray for each person who made a decision for Him in Canada.`}</p>{`
  `}<p parentName="div">{`  `}<strong parentName="p">{`None of this Gospel ministry would be possible without friends like you`}</strong>{`. I am so grateful for your prayerful support and your commitment to evangelism—and I wish you and your loved ones a joyful Thanksgiving.`}</p>{`
  `}<p parentName="div">{`May God richly bless you,`}</p>{`
  `}<img parentName="div" {...{
        "className": "signature",
        "src": "https://static.billygraham.org/sites/billygraham.org/uploads/pro/2020/09/FG-signature1.jpg",
        "alt": ""
      }}></img>{`
  `}<p parentName="div">{`Franklin Graham`}</p>{`
  `}<p parentName="div">{`President & CEO`}</p>{`
  `}<p parentName="div">{`*Names changed to protect privacy`}</p>{`
  `}<hr parentName="div"></hr>{`
  `}<div parentName="div" {...{
        "className": "give-background"
      }}>{`
  `}<h4 parentName="div" {...{
          "className": "cta projects mt-6"
        }}><span parentName="h4">{`YOU`}</span>{` CAN MAKE A DIFFERENCE`}</h4>{`
  `}<h4 parentName="div" {...{
          "className": "mb-6 smaller"
        }}>{`
    GOD WORKS THROUGH PEOPLE LIKE YOU TO CHANGE THE WORLD BY THE POWER OF THE
    GOSPEL.
  `}</h4>{`
  `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`WHERE MOST NEEDED`}</span>{` | The Gospel changes lives—now and for eternity. When you designate a gift for `}<strong parentName="p">{`“Where Most Needed,”`}</strong>{` it is available for immediate ministry opportunities and needs, including the personnel, resources, materials, equipment, and support services vital to the work of evangelism around the world. 
  `}</p>{`
  `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`EVANGELISTIC CRUSADES`}</span>{` | So many people are living without the hope that comes from Jesus Christ. When you send a gift for `}<strong parentName="p">{`Evangelistic Crusades`}</strong>{`, you give people across the globe the chance to hear the Gospel at Franklin Graham Festivals and Will Graham Celebrations.
  `}</p>{`
  `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`GOD LOVES YOU TOURS`}</span>{` | Through your support, the `}<strong parentName="p">{`God Loves You Tours`}</strong>{` can help reach people across our nation with the Good News of Jesus Christ. Each of these powerful one-night events feature a clear presentation of the Gospel from Franklin Graham. 
  `}</p>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      