import { ButtonCollapsible } from '@bgea-js/design-library';
import { SingleDonation as SingleDonationform } from "@bgea-js/form-templates";
import MainContentFall from "../../../../src/content/prayer-letter-fall-special/main-content-fall-special.md";
import LearnMoreFall from "../../../../src/content/prayer-letter-fall-special/learn-more-details-fall-special.md";
import "../../../../src/styles/prayer-letter-fall-special.scss";
import * as React from 'react';
export default {
  ButtonCollapsible,
  SingleDonationform,
  MainContentFall,
  LearnMoreFall,
  React
};