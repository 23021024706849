import React from 'react';
import { Block } from '@bgea-js/react-ui';

const customConfirmationFronteraTShirt = ({
  showRecurrenceText,
  recurrenceText,
  transactionId,
  total,
}) => (
  <div>
  <h4>¡Gracias por inscribirte!</h4>

  <p>Le damos gracias a Dios por tu vida. Utiliza esta página para recibir tu camiseta gratuita en el puesto de camisetas gratuitas. <em>*Hasta agotar existencias.</em></p>
  
  <p><em>“Siempre que oramos por ustedes, damos gracias a Dios, el Padre de nuestro Señor Jesucristo”.</em></p>

  <p>—Colosenses 1:3, NVI</p>
  </div>
);

export default customConfirmationFronteraTShirt;