import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
  `}<h4 parentName="div">{`LIKE A LOST MAN FINDING HIS WAY`}</h4>{`
  `}<p parentName="div">{`Just one week before the Look Up Tour in Idaho Falls, I was in the `}<strong parentName="p">{`Brazilian city of Curitiba`}</strong>{`. The vast and sprawling urban center—known for its beautiful gardens and parks—is built atop a plateau some 3,000 feet above sea level. With a population of 1.9 million (3.7 million across the metropolitan area), it’s the ninth largest city in Brazil. `}</p>{`
  `}<p parentName="div">{`I can’t begin to explain the vibrant atmosphere inside the covered rodeo arena where I preached at the one-day Esperança Curitiba (Hope Curitiba) outreach. Long before the event began, people were festively singing, waving flags, and doing choreographed cheers in the stands. When the worship started, it was clear that the Holy Spirit was moving in that place. `}</p>{`
  `}<img parentName="div" {...{
        "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2023/10/Curitiba-pano-crowd_230901_RN1_4588INET.jpg",
        "alt": ""
      }}></img>{`
  `}<figcaption parentName="div">{`AN ENTHUSIASTIC CROWD of approximately 17,600 people listened as Will Graham shared the Gospel of Jesus Christ.`}</figcaption>{`
  `}<p parentName="div">{`As I took the stage, I quickly focused on the cross of Jesus Christ, sharing how the `}<strong parentName="p">{`overflow crowd of more than 17,000 could have a relationship with Him. Nearly 1,000 people walked forward in repentance to make a commitment to Jesus Christ.`}</strong></p>{`
  `}<div parentName="div" {...{
        "className": "image-float-left"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2023/10/Curitiba_counsel_230901_RN2_4434INT.jpg",
          "alt": ""
        }}></img>{`
  `}</div>{`
  `}<p parentName="div">{`Felipe,* a well-spoken and thoughtful teenager, was one of those who responded. Though many around him were crying during the emotional moment, Felipe looked peaceful and content, like a lost man who had found his way home.`}</p>{`
  `}<p parentName="div">{`“Before, I felt like my head was a blank sheet of paper. There was something missing,” said Felipe, pausing to collect his thoughts and choosing his words carefully. “Now I am feeling that I am starting to write my whole story again. `}<strong parentName="p">{`Yesterday, I felt lost. God really touched my heart during the service.”`}</strong></p>{`
  `}<h4 parentName="div">{`LIFTING UP JESUS’ NAME IN BRITISH COLUMBIA`}</h4>{`
  `}<p parentName="div"><strong parentName="p">{`I just returned from British Columbia, Canada, where we held the Thompson-Okanagan Region Look Up Tour.`}</strong></p>{`
  `}<div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2023/10/Curitiba_Couns_230901_RN2_4453INT.jpg",
          "alt": ""
        }}></img>{`
  `}</div>{`
  `}<p parentName="div">{`Though many consider this one of the most beautiful areas of Canada, its splendor cannot meet people’s deepest needs. `}<strong parentName="p">{`This region is desperate for the hope of Christ`}</strong>{`. We praise God that more than 2,700 people in three communities there heard the Good News that `}<em parentName="p">{`“there is salvation in no one else, for there is no other name under heaven given among men by which we must be saved”`}</em>{` (Acts 4:12, ESV).`}</p>{`
  `}<h4 parentName="div">{`REACHING EUROPE WITH GOD’S TRUTH`}</h4>{`
  `}<p parentName="div">{`As I’ve been busy across North and South America, my father, Franklin Graham, has been sharing the hope of Christ in Europe. `}<strong parentName="p">{`He recently held the Festival of Hope in Essen, Germany, confronting an increasingly secular culture with the truth of God’s Word, and hundreds placed their faith and hope in Jesus Christ.`}</strong></p>{`
  `}<p parentName="div">{`In a few weeks, he will preach in `}<strong parentName="p">{`Rome, Italy, during the Noi Festival`}</strong>{`. Later that month, I’ll have the opportunity to share the Good News in `}<strong parentName="p">{`Budapest, Hungary`}</strong>{`, as the evangelical churches mark the 500`}<sup parentName="p">{`th`}</sup>{` anniversary of Anabaptism there.`}</p>{`
  `}<p parentName="div">{`Would you join me in praying that many in Rome and Budapest would repent of their sins and come to salvation in Christ?`}</p>{`
  `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "god-loves-you-frontera"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`BILLY GRAHAM RAPID RESPONSE TEAM`}</span>{`
  `}</h3>{`
  `}<h4 parentName="div">{`Hope in the midst of sorrow`}</h4>{`
  `}<p parentName="div">{`Psalm 34:18 says, `}<em parentName="p">{`“The Lord is near to the brokenhearted and saves the crushed in spirit”`}</em>{` (ESV). `}<strong parentName="p">{`I was reminded of that truth recently when I had the chance to visit Perry, Florida, which had been devastated by Hurricane Idalia`}</strong>{`. Though it’s been more than a month since the storm came ashore, the impact is still being felt across the region.`}</p>{`
  `}<p parentName="div">{`Crisis-trained Billy Graham Rapid Response Team chaplains are still working in the area to help residents recover and find a “new normal.” The team has ministered to more than 1,500 people, and many have found eternal hope in Christ in the midst of today’s sorrow.`}</p>{`
   `}<h4 parentName="div">{`THANK YOU FOR YOUR FAITHFULNESS`}</h4>{`
   `}<p parentName="div">{`My friends, God is moving and working today. Despite the turmoil around us, He is drawing many to Himself in repentance and faith. `}<strong parentName="p">{`I’ve been blessed to have a front-row seat to watch as the Holy Spirit softens hearts and calls souls, but it wouldn’t be possible without you`}</strong>{`. Thank you for your faithfulness, your support, and—most of all—your prayers. `}</p>{`
  `}<p parentName="div">{`God bless you,`}</p>{`
  `}<img parentName="div" {...{
        "className": "signature",
        "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2022/02/Will-Graham-Signature.png",
        "alt": ""
      }}></img>{`
  `}<p parentName="div">{`Will Graham`}</p>{`
  `}<p parentName="div">{`Executive Vice President`}</p>{`
  `}<p parentName="div">{`*Names changed to protect privacy`}</p>{`
  `}<hr parentName="div"></hr>{`
  `}<div parentName="div" {...{
        "className": "give-background"
      }}>{`
  `}<h4 parentName="div" {...{
          "className": "cta projects mt-6"
        }}><span parentName="h4">{`YOU`}</span>{` CAN MAKE A DIFFERENCE`}</h4>{`
  `}<h4 parentName="div" {...{
          "className": "mb-6 smaller"
        }}>{`
    GOD WORKS THROUGH PEOPLE LIKE YOU TO CHANGE THE WORLD BY THE POWER OF THE
    GOSPEL.
  `}</h4>{`
  `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`WHERE MOST NEEDED`}</span>{` | The Gospel changes lives—now and for eternity. When you designate a gift for `}<strong parentName="p">{`“Where Most Needed,”`}</strong>{` it is available for immediate ministry opportunities and needs, including the personnel, resources, materials, equipment, and support services vital to the work of evangelism around the world. 
  `}</p>{`
  `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`EVANGELISTIC CRUSADES`}</span>{` | So many people are living without the hope that comes from Jesus Christ. When you send a gift for `}<strong parentName="p">{`Evangelistic Crusades`}</strong>{`, you give people across the globe the chance to hear the Gospel at events like the upcoming Franklin Graham Festival in Rome, Italy.
  `}</p>{`
  `}<p parentName="div">{`
    `}<span parentName="p" {...{
            "className": "options"
          }}>{`BILLY GRAHAM RAPID RESPONSE TEAM`}</span>{` | Your contribution in support of the `}<strong parentName="p">{`Billy Graham Rapid Response Team`}</strong>{` helps make it possible for crisis-trained chaplains to bring comfort in Christ’s Name to hurting communities and broken lives.
  `}</p>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      