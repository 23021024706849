import React from 'react';
import { Block, Heading } from '@bgea-js/react-ui';

const CustomConfirmationJp = () => (
  <div>
    <Heading> ご登録ありがとうございました！</Heading>
  </div>
);

export default CustomConfirmationJp;
