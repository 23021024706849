import React from 'react';

const donationHeaderGRC2 = ({ firstName }) => (
  <div>
    <h2>
      Great News! Your free calendar from your friends at the Billy Graham
      Evangelistic Association is on the way, {firstName}.
    </h2>
    <p>*Please allow 4-6 weeks for delivery.</p>
    <br></br>
    <div>
      <p>
        We are praying that this free resource will encourage you and your
        family in the days ahead.
      </p>
      <p>
        <b>Before you go, there are a few things we’d like you to know…</b>
      </p>
      <p>
        The Billy Graham Evangelistic Association (BGEA) is able to offer
        helpful resources like the one you’re receiving because of friends who
        generously make this ministry possible. The Lord is using the gifts and
        prayers of believers—like you—to reach people across the globe with His
        truth and love, especially at a time when so many are experiencing
        uncertainty and fear. Will you help them hear the Good News of God’s
        love?
      </p>
      <blockquote
        style={{
          borderLeft: '1px solid #6e6e6e',
          borderRight: '1px solid #6e6e6e',
          textAlign: 'center',
          marginBottom: 0,
          color: '#6e6e6e',
          padding: '9px 19px',
        }}
      >
        Your gift today will help share <em>“the everlasting gospel”</em>{' '}
        (Revelation 14:6, NKJV) with people around the world who are looking for
        hope—and equip them with powerful Biblical resources to become faithful
        followers of Jesus Christ.
      </blockquote>
    </div>
  </div>
);

export default donationHeaderGRC2;
