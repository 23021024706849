import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
  `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "god-loves-you-tour"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`GOD LOVES YOU TOUR`}</span>{`
  `}</h3>{`
  `}<h4 parentName="div">{`GOD'S HOPE FOR BOTH SIDES OF OUR BORDER`}</h4>{`
  `}<p parentName="div">{`Our country’s southern border is the busiest land crossing in the entire world. `}<strong parentName="p">{`This region has some of the highest rates of poverty in the U.S.—and perhaps the deepest spiritual need`}</strong>{`. Hopelessness is rampant on both sides of the border, and I’m eager to join fellow believers like you to share the Gospel there during the God Loves You Frontera Tour.`}</p>{`
   `}<p parentName="div">{`Starting in February, I’ll preach the `}<em parentName="p">{`“good news of peace through Jesus Christ”`}</em>{` (Acts 10:36, ESV), starting in `}<strong parentName="p">{`Brownsville, Texas`}</strong>{`, before finishing in `}<strong parentName="p">{`Chula Vista, California`}</strong>{`. Along the way, we’ll hold these one-night evangelistic events in `}<strong parentName="p">{`McAllen, Laredo, Eagle Pass, Del Rio, Presidio`}</strong>{`, and `}<strong parentName="p">{`El Paso, Texas`}</strong>{`, as well as `}<strong parentName="p">{`Tucson`}</strong>{` and `}<strong parentName="p">{`Yuma, Arizona.`}</strong></p>{`
  `}<img parentName="div" {...{
        "src": "https://static.billygraham.org/sites/godlovesyoutour.com/uploads/prod/2023/09/frontera_map.jpg",
        "alt": "God loves you frontera tour"
      }}></img>{`
  `}<p parentName="div">{`
  In Texas, the border cities are the poorest in the entire state. Nearly a quarter of the population lives below the federal poverty line and job opportunities are limited. My heart hurts for these men, women, and children, and I’m eager to point them to Jesus Christ, `}<em parentName="p">{`“who is able to do far more abundantly than all that we ask or think, according to the power at work within us”`}</em>{` (Ephesians 3:20, ESV).
  `}</p>{`
  `}<p parentName="div">{`
  Arizona—unlike Southern California and Texas—has one of the largest Native American populations in our country. Reservations like the ones near Tucson and Yuma are full of people who have never heard a clear presentation of the Gospel. Addiction is also rampant in this state, which sees more than five people die from overdoses every single day. I’m praying that Christians in Arizona will invite many of their friends to the Frontera Tour to hear the Good News.
  `}</p>{`
  `}<p parentName="div">{`
  The final stop of the tour is Chula Vista, California. This city is vastly different from the others on the tour—but the need is the same. Homelessness shot up by 14% in the region over the last year. So many individuals and families in this city need to hear about God’s love for them. 
  `}</p>{`
  `}<p parentName="div">{`
  We’re working with churches on both sides of the border to reach as many people as possible with the Good News of Jesus Christ, regardless of their background.
  `}</p>{`
  `}<p parentName="div">{`
  Will you begin praying for the Frontera Tour now? Please pray for the pastors and church leaders who have already started preparing. In just a few weeks, these men and women—along with our field staff—will begin hosting Christian Life and Witness Courses in their churches. This training is designed to help believers deepen their faith and learn how to share it with their friends, loved ones, and neighbors—through the tour or on their own. Please ask God to embolden them as they tell others about His love.
  `}</p>{`
  `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "billy-graham-library"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`BILLY GRAHAM LIBRARY`}</span>{`
  `}</h3>{`
  `}<h4 parentName="div"><em parentName="h4">{`THE JOURNEY OF FAITH`}</em>{` TOUR UPDATE`}</h4>{`
  `}<p parentName="div">{`
  So far this year, over 65,000 visitors have come from all 50 states across our nation and 32 countries to see the updates to the Billy Graham Library’s `}<em parentName="p">{`Journey of Faith`}</em>{` tour. `}<strong parentName="p">{`We praise God for the more than 1,100 people`}</strong>{` who have surrendered their lives to the Savior and have returned home to be a witness for Him in communities across the globe.
  `}</p>{`
  `}<p parentName="div">{`
  If you haven’t been to the Library since it reopened, we invite you to come to Charlotte, North Carolina, and bring loved ones—especially those who may not know the Lord. You’ll be blessed as you see how God worked through my father—and how He can use any life surrendered to Him for His glory.
  `}</p>{`
  `}<div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://static.billygraham.org/sites/billygrahamlibrary.org/uploads/prod/2023/09/anne_and_rachel_ruth.jpg",
          "alt": "Will Graham Preaching"
        }}></img>{`
  `}</div>{`
  `}<p parentName="div">{`
  We have all kinds of free, family-friendly events on the Library calendar, which you can find at `}<strong parentName="p">{`BillyGrahamLibrary.org`}</strong>{`. In just a few weeks, my sister Anne and her daughter Rachel-Ruth Lotz Wright will sign copies of their new book, `}<em parentName="p">{`Preparing to Meet Jesus: A 21-Day Challenge from Salvation to Transformation`}</em>{`. Their book is a powerful devotional that confronts people with life’s most important question: Are you ready to meet Jesus Christ?
  `}</p>{`
  `}<p parentName="div">{`
  `}<strong parentName="p">{`Please pray that the Lord will draw many people to the Library for this event`}</strong>{`—and that while they’re here, they will be challenged to live faithfully for Him in light of His imminent return.
  `}</p>{`
  
  `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "evangelistic-crusades"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`EVANGELISTIC CRUSADES`}</span>{`
  `}</h3>{`
  `}<h4 parentName="div">{`SHARING THE GOSPEL IN GERMANY`}</h4>{`
  `}<p parentName="div">{`
  As I write, I’m preparing to preach in Essen, Germany. Known as the “energy capital” of Europe’s strongest economy, this city is home to nearly half a million people—and countless thousands of them are spiritually lost. More than a third of residents say they don’t know Jesus Christ. Like the rest of Europe, Germany has seen a sharp rise in secularism—and a steep decline in the number of people who identify as Christians. Of those who say they’re believers, the vast majority do not regularly attend church services. 
  `}</p>{`
  `}<p parentName="div">{`
  I praise God for the more than 250 churches across this city that have helped make this Gospel outreach a possibility—and for 1,400 believers who have taken `}<strong parentName="p">{`Christian Life and Witness Courses`}</strong>{`. Hundreds of them have also signed up to serve during the Festival.
  `}</p>
      <p parentName="div">{`Please ask God to draw men, women, and children in Essen into a personal relationship with His Son during this Festival. Pray that local believers will boldly share their faith with their neighbors.`}</p></div>
    <undefined><h3 {...{
        "className": "specialstyling mt-6",
        "id": "good-news-the-globe"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`GOOD NEWS ACROSS THE GLOBE`}</span>{`
  `}</h3>{`
   `}<div {...{
        "className": "image-float-left"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2023/09/wg_preaching.jpg",
          "alt": "Will Graham Preaching"
        }}></img>{`
  `}</div>{`
  `}<p>{`
  My son Will just wrapped up a Celebration in `}<strong parentName="p">{`Curitiba, Brazil`}</strong>{`—and is preparing for a multiday Look Up Tour in `}<strong parentName="p">{`Idaho Falls, Idaho`}</strong>{`. In Curitiba, hundreds of churches across the region gathered weekly to pray for revival in their communities through the Celebration—`}<strong parentName="p">{`and the Lord answered those prayers as nearly 1,000 people`}</strong>{` made decisions for Jesus Christ.
  `}</p>{`
  `}<p>{`
  `}<strong parentName="p">{`Will you “`}<em parentName="strong">{`pray earnestly to the Lord of the harvest`}</em>{`” (Luke 10:2, ESV) for new believers in both cities?`}</strong>{`
  `}</p>{`
  `}<p>{`
    Thank you for your faithful partnership in this Gospel ministry. You are helping share the Good News of Jesus Christ with people far from Him—and we praise God for you.
  `}</p>{`
  `}<p>{`May God richly bless you,`}</p>{`
  `}<img {...{
        "className": "signature",
        "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/01/FG-signature-VectorLineArt.png",
        "alt": ""
      }}></img>{`
  `}<p>{`Franklin Graham`}</p>{`
  `}<p>{`*Names changed to protect privacy`}</p>{`
  `}<hr></hr>{`
  `}<h4 {...{
        "className": "cta projects mt-6"
      }}><span parentName="h4">{`YOU`}</span>{` CAN MAKE A DIFFERENCE`}</h4>{`
  `}<h4 {...{
        "className": "mb-6 smaller"
      }}>{`
    GOD WORKS THROUGH PEOPLE LIKE YOU TO CHANGE THE WORLD BY THE POWER OF THE
    GOSPEL.
  `}</h4>{`
  `}<p>{`
    `}<span parentName="p" {...{
          "className": "options"
        }}>{`WHERE MOST NEEDED`}</span>{` | The Gospel changes lives—now and for eternity. When you designate a gift for `}<strong parentName="p">{`“Where Most Needed,”`}</strong>{` it is available for immediate ministry opportunities and needs, including the personnel, resources, materials, equipment, and support services vital to the work of evangelism around the world. 
  `}</p>{`
  `}<p>{`
    `}<span parentName="p" {...{
          "className": "options"
        }}>{`BILLY GRAHAM RAPID RESPONSE TEAM`}</span>{` | Your contribution in support of the `}<strong parentName="p">{`Billy Graham Rapid Response Team`}</strong>{` helps make it possible for crisis-trained chaplains to bring comfort in Christ’s Name to hurting communities and broken lives.
  `}</p>{`
  `}<p>{`
    `}<span parentName="p" {...{
          "className": "options"
        }}>{`GOD LOVES YOU TOURS`}</span>{` | Through your support, the `}<strong parentName="p">{`God Loves You Tours`}</strong>{` can help reach people across our nation with the Good News of Jesus Christ. Each of these powerful one-night events features a clear presentation of the Gospel from Franklin Graham.
  `}</p>{`
  `}<p>{`
    `}<span parentName="p" {...{
          "className": "options"
        }}>{`EVANGELISTIC CRUSADES`}</span>{` | So many people are living without the hope that comes from Jesus Christ. When you send a gift for `}<strong parentName="p">{`Evangelistic Crusades`}</strong>{`, you give people across the globe the chance to hear the Gospel at events like the Franklin Graham Festival in Essen, Germany, and Will Graham Celebrations in Brazil and Idaho.
  `}</p>
    </undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      