import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
  `}<div parentName="div" {...{
        "className": "image-float-left-small"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://static.billygraham.org/sites/billygrahamlibrary.org/uploads/prod/2023/08/RRT-FG-headshot-17683FLAT.jpg",
          "alt": "Fraklin Graham"
        }}></img>{`
  `}</div>{`
  `}<p parentName="div">{`Dear Friend,`}</p>{`
  `}<p parentName="div">{`I thank God for our Billy Graham Rapid Response Team chaplains. They recently deployed to `}<strong parentName="p">{`Florida`}</strong>{` and `}<strong parentName="p">{`Georgia`}</strong>{` to help residents suffering in the wake of Hurricane Idalia. God is using these men and women as they minister in Jesus’ Name to hurting families and individuals.`}</p>{`
  `}<div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2023/09/RRT_Florida_600x481-1-1.jpg",
          "alt": "Chaplins minister in Jesus' name in communities hit by Idalia."
        }}></img>{`
  `}<figcaption parentName="div"><strong parentName="figcaption">{`Chaplins minister`}</strong>{` in Jesus' Name in communities hit by Idalia.`}</figcaption>{`
  `}</div>{`
  `}<p parentName="div">{`Two of our chaplains met Leah,* who survived the storm but watched it severely damage her house. She was in the midst of desperate prayer when they showed up. Leah told the chaplains she had no hope—she had lost her baby son some time ago and she was burdened by grief. `}<strong parentName="p">{`Our chaplains compassionately listened to her, then asked her about her relationship with God`}</strong>{`. She said she knew about Him but she had no connection to Him. As the chaplains shared the Gospel, Leah listened attentively. After a few minutes, she asked them if they could help her pray to embrace `}<em parentName="p">{`“repentance toward God and of faith in our Lord Jesus Christ”`}</em>{` (Acts 20:21, ESV).`}</p>{`
  `}<p parentName="div"><strong parentName="p">{`Will you pray for Leah—and for our chaplains all over the country? Others are deployed to Spokane, Washington, and Lahaina, Hawaii`}</strong>{`, in the wake of wildfires. Please pray that God will use them to draw hurting people to Himself.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      