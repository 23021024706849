import React from 'react';

const CustomConfirmationLP = ({
  
}) => (
  <>
  <hr />
  <div>
        <p>
        Thank you for your interest in legacy planning. If you ordered the free Legacy Planner, you should receive it by mail within 2 – 3 weeks. If you asked to speak with a ministry representative or indicated that you have made a gift to BGEA in your estate plans, we will be in contact with you soon. May God bless you!
        </p>
  </div>
  </>
  );


  

export default CustomConfirmationLP;