import React from 'react';
import '../../styles/global.module.scss';

const customModalTextWGCC = ({firstName}) => (
  <div>
    <p>
      Great news, {firstName}! You'll soon receive your Christmas card. 
    </p>
    <p>
      We pray this will inspire you to tell others the good news of your
      <em>“Savior, who is Christ the Lord”</em> (Luke 2:11, ESV).
    </p>
    <p>
      In the coming weeks, look for an email from us with more information 
      about Will Graham's upcoming Christmas special. The program will feature 
      an evangelistic message and festive music, and it's a great way to tell 
      your loved ones who don't know Jesus Christ about the Good News of great joy.
    </p>
    <p>
    Thank you for your heart to share the Gospel with people across the globe. 
    May God bless you and your family in the coming weeks as you celebrate 
    the birth of our Savior!
    </p>
  </div>
);

export default customModalTextWGCC;
