import React from 'react';

const customConfirmationMCB = () => (
  <div>
    <h2>
      Thank You for Your Gift
    </h2>
    <p>
      Your donation helps make it possible for more people to hear the Gospel at the upcoming God Loves You Tour. We are grateful for friends like you who have a heart for sharing this Good News around the globe.
    </p>
    <p>
      Please keep the London outreach in prayer as people from across England and Wales experience God's love.
    </p>
    <p>Watch the August 26 event live at <a href="https://billygraham.org/live">BillyGraham.org/Live</a>.</p>
  </div>
);

export default customConfirmationMCB;
