import * as React from 'react';

const thumbnailMain = 'https://cfvod.kaltura.com/p/650742/sp/65074200/thumbnail/entry_id/0_a5pwcd88/width/384/height/216/';
const videos = [
  {
    id: '0_a5pwcd88',
    title: 'Christian Life & Witness Course - Part 1',
    thumbnail: 'https://cfvod.kaltura.com/p/650742/sp/65074200/thumbnail/entry_id/0_a5pwcd88/width/384/height/216/', // Replace with actual thumbnail paths
  },
  {
    id: '0_8ra701fp',
    title: "Christian Life & Witness Course - Part 2",
    thumbnail: 'https://cfvod.kaltura.com/p/650742/sp/65074200/thumbnail/entry_id/0_8ra701fp/width/384/height/216/',
  },
  {
    id: '0_4126s8bw',
    title: 'Christian Life & Witness Course - Part 3',
    thumbnail: 'https://cfvod.kaltura.com/p/650742/sp/65074200/thumbnail/entry_id/0_4126s8bw/width/384/height/216/',
  },
  {
    id: '0_zr6zv3ye',
    title: 'Christian Life & Witness Course - Part 4',
    thumbnail: 'https://cfvod.kaltura.com/p/650742/sp/65074200/thumbnail/entry_id/0_zr6zv3ye/width/384/height/216/',
  },
  {
    id: '0_fmcwwanv',
    title: 'Christian Life & Witness Course - Part 5',
    thumbnail: 'https://cfvod.kaltura.com/p/650742/sp/65074200/thumbnail/entry_id/0_fmcwwanv/width/384/height/216/',
  },
  {
    id: '0_grggc55y',
    title: 'Christian Life & Witness Course - Part 6',
    thumbnail: 'https://cfvod.kaltura.com/p/650742/sp/65074200/thumbnail/entry_id/0_grggc55y/width/384/height/216/',
  },
  {
    id: '0_n4yrj624',
    title: 'Christian Life & Witness Course - Part 7',
    thumbnail: 'https://cfvod.kaltura.com/p/650742/sp/65074200/thumbnail/entry_id/0_n4yrj624/width/384/height/216/',
  },
  {
    id: '0_nsxx47ge',
    title: 'Christian Life & Witness Course - Part 8',
    thumbnail: 'https://cfvod.kaltura.com/p/650742/sp/65074200/thumbnail/entry_id/0_nsxx47ge/width/384/height/216/',
  },
  {
    id: '0_x39zjh0n',
    title: 'Christian Life & Witness Course - Part 9',
    thumbnail: 'https://cfvod.kaltura.com/p/650742/sp/65074200/thumbnail/entry_id/0_x39zjh0n/width/384/height/216/',
  },
];
const KalturaPlayer = ({ kalturaId, title, thumbnail }) => {
  // const iframeSrc = `https://cdnapisec.kaltura.com/p/123456/sp/12345600/embedIframeJs/uiconf_id/12345678/partner_id/123456?iframeembed=true&playerId=kaltura_player&entry_id=${kalturaId}&flashvars[streamerType]=auto`;
  const iframeSrc =  `https://cdnapisec.kaltura.com/p/650742/sp/65074200/embedIframeJs/uiconf_id/49982243/partner_id/650742?iframeembed=true&playerId=kaltura_player_1694532980&entry_id=${kalturaId}`
  return (
    <div>
      {/* <h2>{title}</h2> */}
      <iframe className='player-video'
        src={iframeSrc}
        width="83%"
        height="650"
        allowFullScreen
        frameBorder="0"
        title={title}
      ></iframe>
    </div>
  );
};


function VideoList({pageContext}) {
    const [currentVideo, setCurrentVideo] =React.useState(videos[0])
    return (
        <section className="container-clwc">
        <div className='row align-middle align-center '>
          {/* <div className='small-12 large-9 columns body-copy single-content'> */}
           
            <div>
              <div className="main-video">
                <KalturaPlayer kalturaId={currentVideo.id} title={currentVideo.title}  thumbnail={currentVideo.thumbnail}/>
              </div>
              <div className="thumbnail-videos">
                {videos.map((video) => (
                  <div
                    key={video.id}
                    className="thumbnail"
                    onClick={() => setCurrentVideo(video)}
                  >
                <img className='image-kaltura' src={video.thumbnail} alt={video.title} />
              <p className="subheader">{video.title}</p>
              </div>
            ))}
          </div>
        {/* </div> */}
          </div>
        </div>
   
    </section>
    )
}

export default VideoList;