import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
  `}<p parentName="div">{`Dear Friend,`}</p>{`
  `}<p parentName="div">{`  I’m excited to share what God did in `}<strong parentName="p">{`Essen, Germany`}</strong>{`, during our Festival there a few weeks ago. Many locals were unsure if people would fill the Grugahalle arena—especially in this deeply secular nation. On the night of the Festival, the venue filled to capacity. We had to stop allowing people in. Then the venue gave us permission to use an overflow area, and that quickly filled up too. `}</p>{`
  `}<p parentName="div">{`  Outside the arena, a few protesters with LGBTQ flags gathered, but their presence did not deter the thousands who filed into the arena and overflow area. Our team members invited these men and women inside to hear about God’s love for them—and the hope He offers through salvation in His Son, Jesus Christ. I pray that whether or not they came in, a seed of truth was planted in their hearts.`}</p>{`
  `}<p parentName="div">{`  `}<strong parentName="p">{`The Festival ended up being the largest evangelistic gathering`}</strong>{` in the region since my father’s Crusade in that same arena 30 years ago. More than 200 local pastors and leaders met with me and shared their excitement that so many in their community wanted to hear the Gospel. Praise God for this hunger—and for His promise that His Word will not return void!`}</p>{`
  `}<img parentName="div" {...{
        "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2023/11/Essan-FG-crowd_231001_TP1_3239_INET.jpg",
        "alt": ""
      }}></img>{`
  `}<figcaption parentName="div">{`HUNDREDS IN ESSEN RESPONDED TO THE GOSPEL by making decisions for the Lord.`}</figcaption>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      