import { ButtonCollapsible } from '@bgea-js/design-library';
import { SingleDonation as SingleDonationForm } from '@bgea-js/form-templates';
import MainContentOct from "../../../../src/content/prayer-letter-october/main-content-oct.md";
import LearnMoreOct from "../../../../src/content/prayer-letter-october/learn-more-details-oct.md";
import "../../../../src/styles/prayer-letter-october.scss";
import * as React from 'react';
export default {
  ButtonCollapsible,
  SingleDonationForm,
  MainContentOct,
  LearnMoreOct,
  React
};