import { SingleDonation as SingleDonationMCB } from '@bgea-js/form-templates';
import customConfirmationMCB from "../../../../src/content/mexico-city-buses /customConfirmation";
import styles from "../../../../src/content/mexico-city-buses /styles";
import "../../../../src/content/mexico-city-buses /mexico-buses.scss";
import * as React from 'react';
export default {
  SingleDonationMCB,
  customConfirmationMCB,
  styles,
  React
};