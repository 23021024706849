import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
  `}<p parentName="div">{`Dear Friend,`}</p>{`
  `}<p parentName="div">{`The past few months have been a time of incredibly fruitful ministry across multiple continents. God has done `}<em parentName="p">{`“exceedingly abundantly above all that we ask or think”`}</em>{` (Ephesians 3:20, NKJV), and I thank the Lord for friends like you.`}</p>{`
  `}<p parentName="div"><strong parentName="p">{`I pray you will be encouraged to see how your gifts are impacting eternity and souls are being saved.`}</strong></p>{`
  `}<div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
  `}<img parentName="div" {...{
          "src": "https://static.billygraham.org/sites/billygraham.org/uploads/prod/2023/10/IdFalls_Counsel_230902_TP2_2276INT.jpg",
          "alt": ""
        }}></img>{`
  `}</div>{`
  `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "god-loves-you-tour"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`EVANGELISTIC CRUSADES`}</span>{`
  `}</h3>{`
  `}<h4 parentName="div">{`The Way, the Truth, and the Life`}</h4>{`
  `}<p parentName="div">{`I recently returned from the Look Up Tour in `}<strong parentName="p">{`Idaho Falls`}</strong>{`. During the two-day outreach, I offered Gospel-centered messages about the hope found in Jesus Christ and how we can come to Him in repentance.`}</p>{`
  `}<p parentName="div"><strong parentName="p">{`More than 585 men, women, and children responded to God’s free gift of salvation`}</strong>{`. Among those was a 15-year-old exchange student from Spain named Olivia.* Her host family has been bringing her to church with them, and Olivia knew a bit of the Bible. As I preached from John 3 about finding your purpose in Jesus, her heart was softened and the truth of Scripture became clear to her. `}<strong parentName="p">{`She rushed forward at the invitation and embraced Christ as Savior`}</strong>{`.`}</p>{`
  `}<p parentName="div">{`But that wasn’t the end of the story! Olivia had invited Pravat,* a friend and fellow exchange student from Thailand, to attend with her, and he placed his eternal hope in Jesus as well. `}<strong parentName="p">{`“This is the best day of my entire life,” he beamed to his friends`}</strong>{`. Pravat immediately downloaded a Bible app on to his phone and has since immersed himself in the Word. “He’s very eager to learn everything about Jesus,” Olivia’s host mother shared.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      