import React from 'react';

const donationHeaderProgresiveChristianity = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Great News {firstName}! Your resource is on its way to your inbox.
    </h2>
    <br></br>
    <div>
      <p>
        We praise God that you want to stand boldly for His truth. We hope this
        resource gives you strength as you point people to{' '}
        <em>“the way, the truth, and the life”</em> (John 14:6, ESV).
      </p>
      <p>
        <b>Before you go, there are a few things we’d like you to know…</b>
      </p>
      <p>
        God is using friends like you to reach people with His love through the
        Billy Graham Evangelistic Association. You can read more about these
        Gospel outreaches, including the 24/7 Prayer Line, Billy Graham Rapid
        Response Team, online discipleship resources, and much more{' '}
        <a href="https://billygraham.org/" target="_blank">
          here
        </a>
        .
      </p>
    </div>
  </div>
);

export default donationHeaderProgresiveChristianity;
