import React from 'react';
import { Block } from '@bgea-js/react-ui';

const customConfirmationFronteraDevotional = ({
  showRecurrenceText,
  recurrenceText,
  transactionId,
  total,
}) => (
  <div>
  <h4>Thank you for signing up!</h4>

  <p>We are grateful for you. Use this page to redeem your free devotional at the BGEA booth. <em>*While supplies last</em></p>
  
  <p><em>“We always thank God, the Father of our Lord Jesus Christ, when we pray for you.”</em></p>

  <p>–Colossians 1:3, ESV</p>
  </div>
);

export default customConfirmationFronteraDevotional;